/**
 * /* ---------- Nav links --------------
 *
 * @format
 */

:root {
	--primary-white: #fff;
	--primary-orange: #e21717;
	--primary-dark: rgba(255, 255, 255, 0);
}
body {
	font-family: "Times New Roman", Times, serif;
}
nav {
	z-index: 2;
}

nav a.nav-link {
	font-size: 0.8rem;
	color: #fff;
	letter-spacing: 0.6px;
	cursor: pointer;
	font-family: "Times New Roman", Times, serif;
}

.navbar-brand {
	color: var(--primary-white) !important;
	font-family: "Times New Roman", Times, serif;
}

nav ul li {
	text-transform: uppercase;
}

.navbar > a {
	color: var(--primary-white) !important;
}

.navbar-nav > li > a {
	color: var(--primary-white) !important;
	margin-left: 0.75rem;
	margin-right: 0.75rem;
	border-top: 0.1875rem solid var(--primary-dark);
}
.navbar-collapse {
	justify-content: flex-end !important;
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:hover,
.navbar-nav > li > a.active:focus {
	border-top: 0.1875rem solid var(--primary-orange);
}

/* Header styles */
.header-wrapper {
	position: relative;
	background: url(background.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	height: 90vh;
}

.main-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: "Times New Roman", Times, serif;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 3;
}

.main-info h1 {
	color: var(--primary-orange);
	font-size: 4rem;
	text-transform: uppercase;
	font-weight: 900;
}

.contact-btn {
	color: var(--primary-white);
	border: 1px solid var(--primary-orange);
	padding: 0.625rem 0.75rem;
	text-transform: uppercase;
	margin: 2rem;
	text-decoration: none;
}

.contact-btn:hover {
	background-color: var(--primary-orange);
	color: var(--primary-white);
	transition: 0.2s ease-in-out;
}

/* typed text styles */
.typed-text {
	font-size: 2rem;
	color: var(--primary-white);
}

/* particals style */
.tsparticles-canvas-el {
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 1;
}
